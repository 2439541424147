.toggle-label {
    position: relative;
    width: 250px;
    height: 40px;
    margin: 0px !important;
    /* border: 1px solid #808080; */
    /* margin: 200px auto; */
    background: #F8F8F8;
    color: #f50537;
    cursor: pointer;
    border-radius: 30px;
}

.toggle-label input[type=checkbox] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.toggle-label input[type=checkbox]+.back {
    position: absolute;
    width: 100%;
    height: 100%;
    /* background: #ed1c24; */
    transition: background 150ms linear;
}

.toggle-label input[type=checkbox]+.back .toggle {
    display: block;
    position: absolute;
    content: ' ';
    background: transparent;
    width: 50%;
    height: 100%;
    transition: margin .5s linear;
    /* border: 1px solid #808080; */
    /* border-radius: 0; */
    background: linear-gradient(90deg, rgba(245, 5, 55, 1) 0%, rgba(187, 10, 48, 1) 100%);
    border-radius: 30px;
    height: 85%;
    left: 3px;
    top: auto;
    bottom: auto;
}

.toggle-label input[type=checkbox]:checked+.back .toggle {
    margin-left: 120px;
}

.toggle-label .label {
    display: block;
    position: absolute;
    width: 50%;
    color: #ddd;
    /* vertical-align: middle; */
    text-align: center;
    font-size: 1em;
    border-radius: 30px;
}

.toggle-label .label.on {
    left: 0px;
}

.toggle-label .label.off {
    right: 0px;
}

.toggle-label input[type=checkbox]+.back .label.off,
.toggle-label input[type=checkbox]:checked+.back .label.on {
    color: #f50537;
}

.toggle-label input[type=checkbox]:checked+.back .label.off,
.toggle-label input[type=checkbox]+.back .label.on {
    color: #fff;
}

.table th,
.table td {
    vertical-align: middle;
    text-align: center;
}

a {
    cursor: pointer;
}

.w-190 {
    width: 130px;
}

.customPosition {
    position: inherit !important;
}

.dropdown .dropdown-menu .dropdown-header {
    text-transform: none !important;
    color: var(--danger);
    font-weight: 600;
    font-size: 1em;
    font-family: inherit;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--danger);
}

.action-btn,
.user-search {
    color: var(--danger);
    background-color: #fff;
    border-color: var(--danger);
}

.action-btn:hover {
    background-color: var(--danger);
    color: #fff;
    border-color: var(--danger);
}

.action-btn:active,
.btn-secondary:not(:disabled):not(.disabled):active {
    background-color: var(--danger) !important;
    color: #fff !important;
    border-color: var(--danger) !important;
}

.user-search::placeholder,
.user-search,
.user-search:focus {
    color: var(--danger);
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
    color: var(--danger) !important;
    background-color: #fff !important;
    border-color: var(--danger) !important;
    /* display: inline-block; */
    font-weight: 400;
    /* color: #858796; */
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    /* background-color: transparent; */
    border: 1px solid !important;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.35rem !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.swal2-styled:focus {
    box-shadow: none !important;
}

.swal2-styled.swal2-confirm:hover,
.swal2-styled.swal2-cancel:hover {
    background-color: var(--danger) !important;
    color: #fff !important;
    border-color: var(--danger) !important;
}

/* comments css */

.be-comment-block {
    max-height: 490px;
    overflow-y: auto;
}
.comments-title {
    font-size: 16px;
    color: #262626;
    margin-bottom: 15px;
    font-family: 'Conv_helveticaneuecyr-bold';
}

.be-img-comment {
    width: 60px;
    height: 60px;
    float: left;
    margin-bottom: 15px;
}

.be-ava-comment {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.be-comment-content {
    margin-left: 80px;
}

.be-comment-content span {
    display: inline-block;
    width: 49%;
    margin-bottom: 15px;
}

.be-comment-name {
    font-size: 13px;
    /* font-family: 'Conv_helveticaneuecyr-bold'; */
    font-family: inherit;
}

.be-comment-content a {
    color: #383b43;
}

.be-comment-content span {
    display: inline-block;
    width: 49%;
    margin-bottom: 15px;
}

.be-comment-time {
    text-align: right;
}

.be-comment-time {
    font-size: 11px;
    color: #b4b7c1;
}

.be-comment-text {
    font-size: 13px;
    line-height: 18px;
    color: #7a8192;
    display: block;
    background: #f6f6f7;
    border: 1px solid #edeff2;
    padding: 15px 20px 20px 20px;
}

.form-group.fl_icon .icon {
    position: absolute;
    top: 1px;
    left: 16px;
    width: 48px;
    height: 48px;
    background: #f6f6f7;
    color: #b5b8c2;
    text-align: center;
    line-height: 50px;
    -webkit-border-top-left-radius: 2px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius-topleft: 2px;
    -moz-border-radius-bottomleft: 2px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.form-group .form-input {
    font-size: 13px;
    line-height: 50px;
    font-weight: 400;
    color: #b4b7c1;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #edeff2;
    border-radius: 3px;
}

.form-group.fl_icon .form-input {
    padding-left: 70px;
}

.h-42 {
    height: 42px;
}

.enter-comment-box {
    position: absolute;
    bottom: 0;
}

.bg-black {
    background-color: black;
}

/* .footer-custom {
    padding: 5rem 10rem;
    background-color: #4c4c4c;
    margin-top: 18rem;
    min-height: 20rem;
    color: #fff;
    text-align: right;
} */

@media (max-width: 768px) {
    .footer-custom {
      padding: 0px !important;
    }
}

@media (max-width: 2560px) {
    .custom-iframe {
      padding: 0px !important;
      height: 940px;
    }
}

@media (max-width: 1920px) {
    .custom-iframe {
      padding: 0px !important;
      height: 700px;
    }
}

@media (max-width: 1440px) {
    .custom-iframe {
      padding: 0px !important;
      height: 520px;
    }
}

@media (max-width: 1024px) {
    .custom-iframe {
      padding: 0px !important;
      height: 380px;
    }
}


@media (max-width: 768px) {
    .custom-iframe {
      padding: 0px !important;
      height: 260px;
    }
}

@media (max-width: 540px) {
    .custom-iframe {
      padding: 0px !important;
      height: 290px;
    }
}

@media (max-width: 450px) {
    .custom-iframe {
      padding: 0px !important;
      height: 180px;
    }
}

@media (max-width: 425px) {
    .custom-iframe {
      padding: 0px !important;
      height: 220px;
    }
}

@media (max-width: 411px) {
    .custom-iframe {
      padding: 0px !important;
      height: 200px;
    }
}

@media (max-width: 360px) {
    .custom-iframe {
      padding: 0px !important;
      height: 180px;
    }
}

@media (max-width: 320px) {
    .custom-iframe {
      padding: 0px !important;
      height: 150px;
    }
}

@media (max-width: 280px) {
    .custom-iframe {
      padding: 0px !important;
      height: 130px;
    }
}

@media (width: 768px) {
    .footer-custom {
      padding: 0px !important;
    }
}